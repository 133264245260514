import Hero from '@bluheadless/ui/src/organisms/hero'
import Button from '@bluheadless/ui/src/molecules/button'
import Grid from '@mui/material/Grid'
import {
	Container,
	Title,
	Subtitle,
	Description,
	CtaContainer,
	Link,
	ButtonStyled,
	BackgroundOverlay,
} from './hero-image-text.styled'
import { node, object, shape, arrayOf } from 'prop-types'
import { useTheme } from '@mui/system'
import useMediaQuery from '@mui/material/useMediaQuery'

const HeroImageText = ({
	title,
	titleProps,
	subtitle,
	subtitleProps,
	description,
	descriptionProps,
	cta,
	ctaProps,
	additionalContent,
	backgroundComponent,
	url,
	onClick,
	...props
}) => {
	const fullUrl = url ?? (cta && cta?.length === 1 ? cta[0].url : undefined)
	const fullOnClick = onClick ?? (cta && cta?.length === 1 ? cta[0].onClick : undefined)

	const theme = useTheme()
	const mdUp = useMediaQuery(theme.breakpoints.up('md'))

	return (
		<Hero
			overlayComponent={
				<Container>
					{subtitle && (
						<Subtitle variant="body1" {...subtitleProps}>
							{subtitle}
						</Subtitle>
					)}
					{title && (
						<Title variant="h1" {...titleProps}>
							{title}
						</Title>
					)}
					{description && <Description {...descriptionProps}>{description}</Description>}
					{cta && cta?.length > 0 && (
						<div>
							<CtaContainer
								display="inline-flex"
								justifyContent={props.horizontalAlign}
								hasSiblings={!!subtitle || !!description || !!title}
								{...ctaProps}
							>
								{cta.map(({ label, url, ...rest }, key) => (
									<Grid item key={key}>
										<ButtonStyled
											href={url}
											textColor={rest?.variant == 'text' ? titleProps?.sx.color : null}
											{...rest}
										>
											{label}
										</ButtonStyled>
									</Grid>
								))}
							</CtaContainer>
						</div>
					)}
					{additionalContent}
				</Container>
			}
			backgroundComponent={
				<Link href={fullUrl} onClick={fullOnClick} sx={{ cursor: fullUrl || fullOnClick ? 'pointer' : 'auto' }}>
					{backgroundComponent}
					{props?.className?.includes('bg-overlay') && <BackgroundOverlay />}
				</Link>
			}
			overlayContainerProps={{
				style: {
					...(!mdUp ? { margin: 20 } : { margin: 30 }),
				},
			}}
			{...props}
			verticalAlign="flex-end"
		/>
	)
}

HeroImageText.defaultProps = {
	...Hero.defaultProps,
}

HeroImageText.propTypes = {
	...Hero.propTypes,
	title: node,
	titleProps: object,
	subtitle: node,
	subtitleProps: object,
	description: node,
	descriptionProps: object,
	cta: arrayOf(shape({ ...Button.propTypes })),
	ctaProps: object,
	additionalContent: node,
}

export default HeroImageText
