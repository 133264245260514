import BHButton from '@bluheadless/ui/src/molecules/button'
import Typography from '@bluheadless/ui/src/atoms/typography'
import BHLink from '@bluheadless/ui/src/molecules/link'
import { Grid as MuiGrid } from '@mui/material'
import { styled } from '@mui/system'

const shouldForwardProp = (prop) => {
	return !['hasSiblings', 'textColor'].includes(prop)
}
const Container = styled('div')`
	display: flex;
	flex-direction: column;
`

const Title = styled(Typography)`
	color: ${({ theme }) => theme.palette.common.white};
	letter-spacing: 0;
`
const Subtitle = styled(Typography)`
	font-size: ${({ theme }) => theme.typography.pxToRem(14)};
	font-weight: ${({ theme }) => theme.typography.fontWeightMedium};
	color: ${({ theme }) => theme.palette.common.white};
	letter-spacing: 0.28px;
	line-height: 18px;
	margin-bottom: 11px;
	${({ theme }) => theme.breakpoints.up('md')} {
		font-size: ${({ theme }) => theme.typography.pxToRem(16)};
		letter-spacing: 0.32px;
		line-height: 24px;
		margin-bottom: 0;
	}
`
const Description = styled('div')`
	font-size: ${({ theme }) => theme.typography.pxToRem(14)};
	font-weight: ${({ theme }) => theme.typography.fontWeightMedium};
	color: ${({ theme }) => theme.palette.common.white};
	letter-spacing: 0.28px;
	line-height: 18px;
	margin-top: 10px;
	p {
		font-size: ${({ theme }) => theme.typography.pxToRem(14)};
		font-weight: ${({ theme }) => theme.typography.fontWeightMedium};
		color: ${({ theme }) => theme.palette.common.white};
		letter-spacing: 0.28px;
		line-height: 18px;
	}
	${({ theme }) => theme.breakpoints.up('md')} {
		font-size: ${({ theme }) => theme.typography.pxToRem(16)};
		letter-spacing: 0.32px;
		line-height: 24px;
		margin-top: 20px;
		p {
			font-size: ${({ theme }) => theme.typography.pxToRem(16)};
			letter-spacing: 0.32px;
			line-height: 24px;
		}
	}
`

const CtaContainer = styled(MuiGrid, { shouldForwardProp })`
	flex-direction: column;
	gap: 22px;
	margin-top: ${({ hasSiblings }) => (hasSiblings ? '20px' : '0')};
	${({ theme }) => theme.breakpoints.up('md')} {
		flex-direction: row;
		gap: 38px;
		margin-top: ${({ hasSiblings }) => (hasSiblings ? '35px' : '0')};
	}
`

const Link = styled(BHLink)`
	width: 100%;
`

const ButtonStyled = styled(BHButton, { shouldForwardProp })`
	color: ${({ textColor, theme }) => (textColor?.xs !== '' ? textColor?.xs : theme.palette.common.white)};
	font-size: ${({ theme }) => theme.typography.pxToRem(14)};
	font-weight: ${({ theme }) => theme.typography.fontWeightMedium};
	line-height: 18px;
	&:hover {
		color: ${({ textColor, theme }) => (textColor?.xs !== '' ? textColor?.xs : theme.palette.common.white)};
	}

	&.MuiButton-contained {
		min-height: 36px;
		padding: 6px 40px;
	}

	${({ theme }) => theme.breakpoints.up('md')} {
		color: ${({ textColor, theme }) => (textColor?.md !== '' ? textColor?.md : theme.palette.common.white)};
		font-size: ${({ theme }) => theme.typography.pxToRem(16)};
		line-height: 19px;
		&:hover {
			color: ${({ textColor, theme }) => (textColor?.md !== '' ? textColor?.md : theme.palette.common.white)};
		}
	}
`
const BackgroundOverlay = styled('div')`
	background: linear-gradient(to bottom, rgba(34, 34, 34, 0.07), rgba(34, 34, 34, 0.3));
	height: 100%;
	left: 0;
	position: absolute;
	top: 0;
	width: 100%;
`

export { Container, Title, Subtitle, Description, CtaContainer, Link, ButtonStyled, BackgroundOverlay }
